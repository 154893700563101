import React from "react";
import Lottie from "react-lottie";

import {
  Facebook,
  Instagram,
  Soundcloud,
  Spotify,
  Twitter,
  Youtube
} from "grommet-icons";
import Fade from "react-reveal/Fade";

import Layout from "../components/layout";
import SEO from "../components/seo";
import bg from "../images/lau-cover-bg.jpg";
import bgMask from "../images/lau-cover-masked.png";
import bio from "../images/biolau2.jpg";
import lightning from "../images/lightning.json";
import design from "../images/logo_lau-x-design.svg";
import music from "../images/logo_lau-x-music.svg";
import prod from "../images/logo_lau-x-prod.svg";

import bgDesign from "../images/laudsgn3.jpg";
import bgMusic from "../images/laumusic3.jpg";
import bgProd from "../images/lauproduction3.jpg";
import bgContact from "../images/contact.jpg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Lau`,
          `ohlalau`,
          `music`,
          `design`,
          `oh la lau`,
          `brand`,
          `Laura Jimenez`,
          `signer`,
          `musician`,
          `designer`,
          `producer`,
          `branding`,
        ]}
      />

      <section className="h-100vh fixed top-0 overflow-hidden w-full flex items-center">
        <div
          className="absolute inset-0 bg-cover bg-center lau-bg"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        />
        <div className="absolute bg-cover bg-center lightning-wrap">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: lightning,
              background: "transparent",
            }}
            className="scale-110"
          />
        </div>
        <div
          className="absolute inset-0 bg-cover bg-center lau-bg"
          style={{
            backgroundImage: `url(${bgMask})`,
          }}
        />
        <div className="lau-heading relative z-10 px-2 w-full md:w-1/2 lg:w-1/4 mx-auto text-white text-center px-10 sm:px-4 px-0">
          <Fade top distance="2em">
            <div className="-mb-3 max-w-20">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 80">
                <title>LAU</title>
                <polygon
                  className="fill-current shadow"
                  points="80.3 62.19 66.88 23.27 65.54 23.27 54.8 23.27 53.46 23.27 40.04 62.19 52.12 62.19 60.17 38.84 68.22 62.19 80.3 62.19"
                />
                <polygon
                  className="fill-current shadow"
                  points="13.2 51.5 13.2 23.27 1.12 23.27 1.12 52.8 1.12 62.19 13.2 62.19 34.67 62.19 34.67 51.5 13.2 51.5"
                />
                <g>
                  <path
                    className="fill-current shadow"
                    d="M91,23.27H85.67a4,4,0,1,0,0,8.05V50.11A12.07,12.07,0,0,0,97.74,62.19h0V30A6.71,6.71,0,0,0,91,23.27Z"
                  />
                  <rect
                    className="fill-current shadow"
                    x="107.14"
                    y="23.27"
                    width="1.34"
                    height="38.92"
                  />
                  <rect
                    className="fill-current shadow"
                    x="101.77"
                    y="23.27"
                    width="1.34"
                    height="38.92"
                  />
                  <path
                    className="fill-current shadow"
                    d="M124.58,23.27h-5.37A6.72,6.72,0,0,0,112.5,30V62.19a12.08,12.08,0,0,0,12.08-12.08V31.32a4,4,0,1,0,0-8Z"
                  />
                </g>
              </svg>
            </div>
          </Fade>
          <Fade bottom distance="2em">
            <p
              className="md:text-lg lg:text-2xl uppercase tracking-widest"
              style={{ textShadow: "0px 1px 21px rgba(0, 0, 0, 0.83)" }}
            >
              Music × Design × Production
            </p>
          </Fade>
        </div>
      </section>

      <section className="relative mt-100vh z-10 text-center bg-black text-white py-20 leading-tight px-6 sm:px-4 px-0">
        <Fade bottom distance="2em">
          <div className="mx-auto md:w-1/2 lg:w-2/3 py-20">
            <h1 className="text-2xl md:text-4xl lg:text-5xl font-light">
              Hi, I am Lau. I&apos;m a{" "}
              <span className="font-bold">designer</span>,{" "}
              <span className="font-bold">musician</span>, and{" "}
              <span className="font-bold">producer</span> constantly turning
              imagination into artistic expressions and business realities.
            </h1>
          </div>
        </Fade>
      </section>
      <section className="px-6 sm:px-4 px-0 relative bg-gray-100 z-10">
        <div className="px-2 md:px-8 lg:px-20 flex flex-col md:flex-row py-20 items-center">
          <Fade left distance="2em">
            <div className="md:w-1/2 lg:w-2/5 md:pr-20 mb-10 md:mb-0">
              <h2 className="font-extrabold tracking-tight text-xl md:text-3xl lg:text-5xl font-bold mb-8">
                About Me
              </h2>
              <p className="mb-5 md:text-lg lg:text-xl">
                A creative soul living in Bogotá, constantly daring conventions
                with creativity and inspiration.{" "}
              </p>
              <p className="mb-5 md:text-lg lg:text-xl">
                Currently, building brands as the Creative Director of{" "}
                <a
                  className="font-bold inline-block -mb-1 border-b border-gray-600 border-dotted"
                  href="https://pawa.rocks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PAWA
                </a>
                , consulting innovators as a Managing Partner and Head of
                Branding at{" "}
                <a
                  className="font-bold inline-block -mb-1 border-b border-gray-600 border-dotted"
                  href="https://thinklo.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  THINKLO
                </a>
                , and creating whimsical music & multimedia content with
                collaborators around the globe, I am always looking forward for
                the next creative challenge.
              </p>
            </div>
          </Fade>
          <Fade right distance="2em">
            <div className="md:w-1/2 lg:w-2/5 ml-auto">
              <img src={bio} alt="Bio" />
            </div>
          </Fade>
        </div>
      </section>
      <section
        className="relative z-10 px-6 sm:px-4 px-0 bg-black text-white py-0 lg:py-20 bg-cover bg-fixed bg-right-top"
        style={{ backgroundImage: `url(${bgDesign})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 md:bg-opacity-0" />
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(90deg, rgba(0,0,0,0.4) 35%, rgba(0,0,0,0) 70%)",
          }}
        />
        <div
          className="absolute inset-0 md:hidden"
          style={{
            background:
              "repeating-linear-gradient(-55deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 2px, rgba(0,0,0,0.15) 2px, rgba(0,0,0,0.15) 4px)",
          }}
        />
        <div className="px-2 md:px-8 lg:px-20 flex flex-row py-10 lg:py-20 items-center relative z-10">
          <div className="md:w-1/2 lg:w-2/5 md:pr-20">
            <Fade bottom distance="2em">
              <img
                src={design}
                alt="Lau × Design"
                className="w-full sm:w-2/3 md:w-full mb-5"
              />
              <p className="mb-5 md:text-lg lg:text-xl">
                I am the Co-Founder and Creative Director at the brand
                consulting agency{" "}
                <a
                  className="font-bold inline-block -mb-1 border-b border-gray-100 border-dotted"
                  href="https://pawa.rocks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PAWA
                </a>
                , where my job consists on the creative vision of the agency to
                help create and deliver experiences that empower, enrich, and
                energize people and brands. I also serve as a Managing Partner &
                Head of Brands at{" "}
                <a
                  className="font-bold inline-block -mb-1 border-b border-gray-100 border-dotted"
                  href="https://thinklo.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  THINKLO
                </a>{" "}
                Innovation Group, a strategic consulting firm that helps
                ambitious companies to create and evolve brands, products, and
                services.
              </p>
              <p className="mb-5 md:text-lg lg:text-xl">
                I have a Bachelor&apos;s Degree in Visual Communications with
                Major in Multimedia. I have designed hundreds of brands for
                companies and products over more than 20 countries.
              </p>
            </Fade>
          </div>
        </div>
      </section>
      <section
        className="relative z-10 px-6 sm:px-4 px-0 bg-black text-white py-0 lg:py-20 bg-cover bg-fixed bg-left-top"
        style={{ backgroundImage: `url(${bgMusic})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 md:bg-opacity-10" />
        <div
          className="absolute inset-0 md:hidden"
          style={{
            background:
              "repeating-linear-gradient(-55deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 2px, rgba(0,0,0,0.15) 2px, rgba(0,0,0,0.15) 4px)",
          }}
        />
        <div className="px-2 md:px-8 lg:px-20 flex flex-row py-10 lg:py-20 items-center relative z-10">
          <div className="md:w-1/2 lg:w-2/5 md:pr-20 md:ml-auto">
            <Fade bottom distance="2em">
              <img
                src={music}
                alt="Lau × Music"
                className="w-full sm:w-2/3 md:w-full mb-5"
              />
              <p className="mb-5 md:text-lg lg:text-xl">
                Music has been an important part of my life since I was little,
                having my first performance on national television at the age of
                14 years old, which led me to start my studies in music theory
                and vocals shortly after that.
              </p>
              <p className="mb-5 md:text-lg lg:text-xl">
                Eclectic from my guts, I love to experiment with all kinds of
                genres including celtic music, electronic, pop, rock, and even
                children&apos;s music. Currently, I work with multiple producers
                in different countries composing, producing, and performing.
              </p>
              <p className="mb-5 md:text-lg lg:text-xl">
                If you want to explore collaborating or producing your next
                inspiring harmonies with me, just shout an email to start a
                creative journey together.
              </p>
            </Fade>
          </div>
        </div>
      </section>
      <section
        className="relative z-10 px-6 sm:px-4 px-0 bg-black text-white py-0 lg:py-20 bg-cover bg-fixed bg-bottom"
        style={{ backgroundImage: `url(${bgProd})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 md:bg-opacity-10" />
        <div
          className="absolute inset-0 md:hidden"
          style={{
            background:
              "repeating-linear-gradient(-55deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 2px, rgba(0,0,0,0.15) 2px, rgba(0,0,0,0.15) 4px)",
          }}
        />
        <div className="px-2 md:px-8 lg:px-20 flex flex-row py-10 lg:py-20 items-center relative z-10">
          <div className="md:w-1/2 lg:w-2/5 md:pr-20">
            <Fade bottom distance="2em">
              <img
                src={prod}
                alt="Lau × Prod"
                className="w-full sm:w-2/3 md:w-full mb-5"
              />
              <p className="mb-5 md:text-lg lg:text-xl">
                Media production has been a bridge between my artistic DNA and
                designer expertise. I&apos;m always pursing to leverage my
                creativity and professional experience to devise and produce
                engaging content, including music videos, vlogs, social media,
                or any interesting form of expressions.
              </p>
              <p className="mb-5 md:text-lg lg:text-xl">
                Although my professional career has gravitated towards building
                brands, and my artistic passion towards producing music, my
                formal education in multimedia has never stopped beating.
              </p>
              <p className="mb-5 md:text-lg lg:text-xl">
                If you are curious about it, you can follow my social channels
                or send me an email with your inquires.
              </p>
            </Fade>
          </div>
        </div>
      </section>
      <section className="relative z-10 px-6 sm:px-4 px-0 bg-white text-gray-800 py-10 lg:py-20 leading-tight border-t-8 border-solid border-gray-800">
        <div className="px-2 md:px-8 lg:px-20 flex flex-col md:flex-row md:py-20 items-start relative z-10">
          <div className="w-full md:w-1/2 lg:w-2/5 md:pr-20">
            <Fade bottom distance="2em">
              <h2 className="font-extrabold tracking-tight text-2xl md:text-4xl lg:text-5xl font-bold pb-8 md:pb-0 mb-8 border-0 border-b border-gray-300 md:border-b-0">
                Where to find me?
              </h2>
              <p className="mb-5 md:text-lg lg:text-xl">
                Just look at the clouds.
              </p>
            </Fade>
          </div>
          <div className="md:w-1/2 md:pr-20 md:ml-auto">
            <div className="md:grid md:grid-flow-col md:grid-cols-2 md:grid-rows-3 md:gap-4">
              <Fade bottom distance="2em">
                <div className="mb-8 md:mb-10">
                  <a
                    className="text-xl md-text-3xl lg:text-5xl font-medium hover:text-black focus:text-black tracking-tight"
                    href="https://www.youtube.com/lauxlau"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YouTube
                  </a>
                  <p>Personal music videos and vlogs.</p>
                </div>
                <div className="mb-8 md:mb-10">
                  <a
                    className="text-xl md-text-3xl lg:text-5xl font-medium hover:text-black focus:text-black tracking-tight"
                    href="https://www.instagram.com/lau.xlau/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                  <p>Short singing videos & life stories.</p>
                </div>
                <div className="mb-8 md:mb-10">
                  <a
                    className="text-xl md-text-3xl lg:text-5xl font-medium hover:text-black focus:text-black tracking-tight"
                    href="https://twitter.com/_lauxlau"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                  <p>Announcements and shouts.</p>
                </div>
                <div className="mb-8 md:mb-10">
                  <a
                    className="text-xl md-text-3xl lg:text-5xl font-medium hover:text-black focus:text-black tracking-tight"
                    href="https://www.facebook.com/lauxlaumusic"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                  <p>A legacy tiny bit of everything.</p>
                </div>
                <div className="mb-8 md:mb-10">
                  <a
                    className="text-xl md-text-3xl lg:text-5xl font-medium hover:text-black focus:text-black tracking-tight"
                    href="https://soundcloud.com/lauxlaumusic"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SoundCloud
                  </a>
                  <p>Music explorations.</p>
                </div>
                <div className="mb-8 md:mb-10">
                  <a
                    className="text-xl md-text-3xl lg:text-5xl font-medium hover:text-black focus:text-black tracking-tight"
                    href="https://spotify.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Spotify
                  </a>
                  <p>Coming soon!</p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section
        className="relative z-10 px-6 sm:px-4 px-0 bg-black text-white h-100vh bg-cover bg-fixed bg-center"
        style={{ backgroundImage: `url(${bgContact})` }}
      >
        <div className="px-2 md:px-8 lg:px-20 flex flex-row text-center items-center h-full relative z-10">
          <Fade bottom distance="2em">
            <div className="md:w-1/2 lg:w-2/5 mx-auto">
              <h2
                className="text-4xl md:text-5xl lg:text-6xl font-bold mb-8"
                style={{ textShadow: "0px 1px 20px rgba(0, 0, 0, 0.23)" }}
              >
                Let&apos;s Chat!
              </h2>
            </div>
          </Fade>
        </div>
      </section>
      <section className="relative z-10 px-6 sm:px-4 px-0 bg-gray-100 text-gray-800 py-8 md:py-10 lg:py-20 leading-tight">
        <div className="px-2 md:px-8 lg:px-20 flex flex-row md:py-10 items-center relative z-10">
          <div className="md:w-1/2 lg:w-2/5 lg:pr-20">
            <Fade left distance="2em">
              <p className="mb-8 md:text-xl lg:text-2xl">
                I am available for music and video production, collaborations,
                and public speaking.
              </p>
              <p className="md:text-md lg:text-lg">Send me a message at:</p>
              <p className="mb-6 md:mb-8 text-xl md:text-2xl lg:text-3xl">
                <a
                  className="font-medium tracking-tight text-gray-900 hover:text-black focus:text-black"
                  href="mailto:lau@pawa.rocks"
                >
                  lau@pawa.rocks
                </a>
              </p>
              <p className="mb-6 md:mb-8 md:text-md lg:text-lg">
                For design and consulting jobs, you can contact me at:{" "}
                <a
                  className="font-bold inline-block -mb-1 border-b border-gray-600 border-dotted"
                  href="mailto:lau@thinklo.com"
                >
                  lau@thinklo.com
                </a>
                , visit{" "}
                <a
                  className="font-bold inline-block -mb-1 border-b border-gray-600 border-dotted"
                  href="https://pawa.rocks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  pawa.rocks
                </a>
                , or{" "}
                <a
                  className="font-bold inline-block -mb-1 border-b border-gray-600 border-dotted"
                  href="https://thinklo.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  thinklo.com
                </a>
                .
              </p>
              <p className="mb-6 md:mb-8 md:text-md lg:text-lg">
                You can also reach me on my social channels.
              </p>
            </Fade>
            <div>
              <Fade left distance="2em">
                <a
                  href="https://www.youtube.com/lauxlau"
                  className="text-gray-200 hover:text-black focus:text-black text-opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Youtube className="h-4 w-4 fill-current mr-4" />
                </a>
                <a
                  href="https://www.instagram.com/lau.xlau/"
                  className="text-gray-200 hover:text-black focus:text-black text-opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram className="h-4 w-4 fill-current mr-4" />
                </a>
                <a
                  href="https://twitter.com/_lauxlau"
                  className="text-gray-200 hover:text-black focus:text-black text-opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter className="h-4 w-4 fill-current mr-4" />
                </a>
                <a
                  href="https://www.facebook.com/lauxlaumusic"
                  className="text-gray-200 hover:text-black focus:text-black text-opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook className="h-4 w-4 fill-current mr-4" />
                </a>
                <a
                  href="https://soundcloud.com/lauxlaumusic"
                  className="text-gray-200 hover:text-black focus:text-black text-opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Soundcloud className="h-4 w-4 fill-current mr-4" />
                </a>
                <a
                  href="https://spotify.com/"
                  className="text-gray-200 hover:text-black focus:text-black text-opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Spotify className="h-4 w-4 fill-current mr-4" />
                </a>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
